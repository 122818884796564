<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-01 15:29:17
 * @LastEditors: Zhongyu
 * @LastEditTime: 2022-01-17 09:45:26
-->
<template>
  <div class="home">
    <div class="home-banner">
      <el-carousel autoplay :interval="5000">
        <el-carousel-item v-for="(item, i) in banners" :key="i">
          <img :src="$utils.picturePrefix(item.image)" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="home-hot">
      <div class="service-order">
        <div class="title">
          <!-- <img src="../../assets/img/home/img-service-order-title-icon.png" /> -->
          <span class="iconfont icon-dingdan1"></span>
          能匠服务订单
        </div>
        <!-- <div class="order-list"> -->
        <div class="service-table-swiper swiper-container-vertical">
          <div v-if="orderList.length > 0" class="swiper-wrapper">
            <ul
              class="swiper-slide"
              v-for="(item, i) in orderList"
              :key="i"
              @mouseleave="serviceMouseleave(serviceSwiper)"
              @mouseenter="serviceMouseenter(serviceSwiper)"
            >
              <li>
                <img src="../../assets/img/home/img-service-order-icon.png" />
              </li>
              <li>{{ $utils.formattedName(item.clientName) }}</li>
              <li>{{ $utils.formattedPhone(item.clientPhone) }}</li>
              <li style="color: rgba(48, 114, 246, 1)">
                {{ $utils.orderTypeName(item.type) }}
              </li>
              <li>{{ item.productType }}</li>
              <li>
                预计收益
                <span>
                  <span style="font-size: 14px">￥</span>{{ item.money }}
                </span>
              </li>
              <li>{{ item.serviceTime }}</li>
              <li style="width: 82px">
                <div class="operation" @click="goGrabSingleDetail(item.id)">
                  立即抢单
                </div>
              </li>
            </ul>
          </div>

          <div v-else class="default">
            <img src="../../assets/img/default/list-default.png" alt="" />
            <p>暂无订单</p>
          </div>
        </div>
        <!-- </div> -->
      </div>
      <div class="nengjiang-star">
        <div class="title">
          <span class="iconfont icon-jinqiancunqian-"></span>
          <!-- <img src="../../assets/img/home/img-goldsmith-title-icon.png" /> -->
          能匠之星
        </div>
        <div
          class="
            nengjiang-overflow nengjiang-table-swiper
            swiper-container-vertical
          "
        >
          <ul
            v-if="sellingList.length > 0"
            class="swiper-wrapper"
            @mouseleave="serviceMouseleave(nengjiangSwiper)"
            @mouseenter="serviceMouseenter(nengjiangSwiper)"
          >
            <li class="swiper-slide" v-for="(item, i) in sellingList" :key="i">
              <div>
                恭喜{{ item.city }}{{ item.area }}城市合伙人{{
                  item.installationName
                }}{{ item.productName }}
                <span>
                  <span style="font-size: 14px">￥</span>{{ item.money }}
                </span>
              </div>
            </li>
          </ul>
          <div class="default" v-else>
            <img src="../../assets/img/default/rank-default.png" alt="" />
            <p>暂无数据</p>
          </div>
        </div>
      </div>
    </div>
    <div class="home-product">
      <div class="home-title">
        <div class="title-top">
          <img src="../../assets/img/home/img-home-title-left.png" />
          <div class="text">产品中心</div>
          <img src="../../assets/img/home/img-home-title-right.png" />
        </div>
        <div class="title-bootom">
          <img src="../../assets/img/home/img-home-title-bootom.png" />
        </div>
      </div>
      <div class="product-list">
        <div
          class="item"
          v-for="(item, i) in productList"
          :key="i"
          @click="$router.push(`/product/detail?id=${item.id}`)"
        >
          <div class="item-img">
            <img :src="$utils.picturePrefix(item.image)" />
          </div>
          <div class="desc" v-if="false">
            <p class="text-ellipsis">{{ item.typeName }}</p>
            <p class="text-ellipsis">{{ item.name }}</p>
            <!-- <i class="el-icon-right"></i> -->
            <span class="iconfont icon-jiantou_xiangyou"></span>
          </div>
        </div>
        <!-- <div class="item">
          <img src="../../assets/img/home/img-2.png" />
          <div class="desc">
            <p class="text-ellipsis">RO100-L1（A）</p>
            <p class="text-ellipsis">单出水直饮净水器</p>
            <i class="el-icon-right"></i>
          </div>
        </div>
        <div class="item">
          <img src="../../assets/img/home/img-2.png" />
          <div class="desc">
            <p class="text-ellipsis">RO100-L1（A）</p>
            <p class="text-ellipsis">单出水直饮净水器</p>
            <i class="el-icon-right"></i>
          </div>
        </div> -->
      </div>
    </div>
    <div class="home-customer-case" v-if="customerCaseList.length > 0">
      <div class="home-title">
        <div class="title-top">
          <img src="../../assets/img/home/img-home-title-left.png" />
          <div class="text">平台保障</div>
          <img src="../../assets/img/home/img-home-title-right.png" />
        </div>
        <div class="title-bootom">
          <img src="../../assets/img/home/img-home-title-bootom.png" />
        </div>
      </div>
      <div class="customer-case-list">
        <div class="item">
          <img src="../../assets/water-img/jiedanwuyou.jpeg" />
        </div>
        <div class="item">
          <img src="../../assets/water-img/jiangxing.jpeg" />
        </div>
        <div class="item">
          <img src="../../assets/water-img/hailiang.jpeg" />
        </div>
        <div class="item">
          <img src="../../assets/water-img/haoping.jpeg" />
        </div>
        <!-- <div
          class="item"
          v-for="(item, i) in customerCaseList"
          :key="i"
          :class="{ bottom: i % 2 == 1 }"
          @click="caseDetails(item.id)"
        >
          <img v-show="i % 2 == 0" :src="$utils.picturePrefix(item.image)" />
          <div class="item-body">
            <p class="desc text-ellipsis-two">
              {{ item.introduction }}
            </p>
            <div class="bottom">
              <div class="time">{{ item.createDate }}</div>
              <el-button>查看</el-button>
            </div>
          </div>
          <img v-show="i % 2 == 1" :src="$utils.picturePrefix(item.image)" />
        </div> -->
      </div>
    </div>
    <div class="home-data-overview" v-if="hidden">
      <div class="home-title">
        <div class="title-top">
          <img src="../../assets/img/home/img-home-title-left.png" />
          <div class="text">数据总览</div>
          <img src="../../assets/img/home/img-home-title-right.png" />
        </div>
        <div class="title-bootom">
          <img src="../../assets/img/home/img-home-title-bootom.png" />
        </div>
      </div>
      <div class="overview-list">
        <div class="item item-bottom item1">
          <div class="icon">
            <div class="icon-short"></div>
            <div class="icon-long"></div>
          </div>
          <div class="text">
            <div class="number">1.2<span>万</span></div>
            <div class="desc">用户量达到1.2万</div>
          </div>
        </div>
        <div class="item item-top item2">
          <div class="icon">
            <div class="icon-short"></div>
            <div class="icon-long"></div>
          </div>
          <div class="text">
            <div class="number">50<span>万</span></div>
            <div class="desc">订单数量多，保证安装师傅接单</div>
          </div>
        </div>
        <div class="item item-top item3">
          <div class="icon">
            <div class="icon-short"></div>
            <div class="icon-long"></div>
          </div>
          <div class="text">
            <div class="number">500<span>万</span></div>
            <div class="desc">众多经验丰富安装师傅</div>
          </div>
        </div>
        <div class="item item-bottom item4">
          <div class="icon">
            <div class="icon-short"></div>
            <div class="icon-long"></div>
          </div>
          <div class="text">
            <div class="number">800<span>万</span></div>
            <div class="desc">年销售量突破</div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-news-center" v-if="hidden">
      <div class="home-title">
        <div class="title-top">
          <img src="../../assets/img/home/img-home-title-left.png" />
          <div class="text">新闻中心</div>
          <img src="../../assets/img/home/img-home-title-right.png" />
        </div>
        <div class="title-bootom">
          <img src="../../assets/img/home/img-home-title-bootom.png" />
        </div>
      </div>
      <div class="news-table-swiper swiper-container-horizontal">
        <div
          class="news-center-list swiper-wrapper"
          @mouseleave="serviceMouseleave(newsSwiper)"
          @mouseenter="serviceMouseenter(newsSwiper)"
        >
          <div
            class="item swiper-slide"
            v-for="(item, i) in consultingList"
            :key="i"
            @click="newDetails(item.id)"
          >
            <div class="news-img">
              <img :src="$utils.picturePrefix(item.image)" />
            </div>
            <div class="title text-ellipsis">
              {{ item.title }}
            </div>
            <div class="desc text-ellipsis-two">
              {{ item.introduction }}
            </div>
            <div class="time">
              <div class="line"></div>
              {{ item.createDate }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="home-order-flow">
      <img src="../../assets/water-img/jiedan.jpeg" alt="" />
    </div>

    <div class="home-coll">
      <img src="../../assets/water-img/hezuo.jpeg" alt="" />
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
// import style (>= Swiper 6.x) Swiper, SwiperSlide,
import "swiper/dist/css/swiper.min.css";

export default {
  components: {},
  data() {
    return {
      hidden: false,
      // 能匠服务订单
      orderList: [],
      // 能匠之星
      sellingList: [],
      // 新闻中心
      consultingList: [],
      // 客户案例
      customerCaseList: [],
      // 我们的产品
      productList: [],
      banners: [],
      serviceSwiper: null,
      nengjiangSwiper: null,
      newsSwiper: null,
      // 弹框
      dialogFormVisible: false,
      phone_message: "请输入手机号",
      // formLabelWidth:'',
      margin: ""
    };
  },
  computed: {
    isPower() {
      return this.$store.getters.isPower;
    },
    getUser() {
      return this.$store.getters.user;
    }
  },
  mounted() {
    this.getServiceOrder();
    this.getSellingOrder();
    this.getHomeData();
    this.getBanner();
    if (this.isPower) {
      this.getSigningProcess();
    }
  },
  methods: {
    bannerHeight() {
      let width = window.innerWidth;
      let getClass = document.getElementsByClassName("home-banner")[0];

      // eslint-disable-next-line no-self-assign
      width < 1200 ? (width = 1200) : (width = width);
      let height = (width / 1920) * 440;
      console.log(getClass, height);
      return (getClass.style.height = height + "px");
    },
    getServiceOrder() {
      this.$api.getServiceOrder().then(res => {
        this.orderList = res.data;
        if (res.data.length > 10) {
          this.$nextTick(function() {
            this.serviceSwiper = new Swiper({
              el: ".service-table-swiper",
              direction: "vertical",
              initialSlide: 0,
              watchOverflow: true,
              loop: true,
              slidesPerView: 10,
              spaceBetween: 0,
              autoplay: {
                delay: 2500,
                stopOnLastSlide: false,
                disableOnInteraction: false
              },
              mousewheel: true //开启鼠标
              // speed: 80 //滚动速度
            });
          });
        }
      });
      //
    },
    getSigningProcess() {
      this.$api.getSigningProcess().then(res => {
        this.margin = res.data.margin;
      });
    },
    getSellingOrder() {
      this.$api.getSellingOrder().then(res => {
        this.sellingList = res.data;
        if (res.data.length > 6) {
          this.$nextTick(function() {
            this.nengjiangSwiper = new Swiper({
              el: ".nengjiang-table-swiper",
              direction: "vertical",
              initialSlide: 0,
              watchOverflow: true,
              loop: true,
              slidesPerView: 6,
              spaceBetween: 0,
              // mousewheel: true, //开启鼠标
              speed: 2000, //滚动速度2500
              autoplay: {
                delay: 0,
                stopOnLastSlide: false,
                disableOnInteraction: false
              }
            });
          });
        }
      });
    },

    getBanner() {
      this.$api.getBanner({ type: 1 }).then(res => {
        this.banners = res.data;
        setTimeout(() => {
          this.bannerHeight();
        }, 0);
      });
    },
    goGrabSingleDetail(id) {
      if (this.isPower) {
        this.$api
          .isArea({
            id: id,
            userId: this.getUser.id
          })
          .then(res => {
            if (this.getUser && this.getUser.isPartner) {
              let margin = this.getUser.margin * 100;
              let margin1 = this.margin * 100 * 0.8;

              if (margin > margin1) {
                this.$router.push(`/grab-single/detail?id=${id}`);
              } else {
                this.$alert(
                  "保证金余额不足,请联系管理员进行充值: " +
                    `${sessionStorage.getItem("contactPhone") || ""}`,
                  "提示",
                  {
                    confirmButtonText: "确定"
                    // callback: action => {
                    //   // this.$message({
                    //   //   type: "info",
                    //   //   message: `取消抢单!`
                    //   // });
                    // }
                  }
                );
              }
              // this.$router.push(`/grab-single/detail?id=${id}`);
            } else {
              this.$message.error("您还不是城市合伙人，请联系管理员");
            }
          })
          .catch(err => {
            this.$message.error(err?.msg);
          });
      } else {
        this.$message.error("请登录");
        this.$router.push(`/login`);
      }
    },

    getHomeData() {
      this.$api.getHomeData().then(res => {
        // 新闻中心
        this.consultingList = res.data.consultingList;
        // 客户案例
        this.customerCaseList = res.data.customerCaseList;
        // 我们的产品
        this.productList = res.data.productList;
        // this.sellingList = res.data;
        if (res.data.consultingList.length > 3) {
          this.$nextTick(function() {
            this.newsSwiper = new Swiper({
              el: ".news-table-swiper",
              direction: "horizontal",
              initialSlide: 0,
              spaceBetween: 0,
              slidesPerView: "auto",
              watchOverflow: true,
              loop: true,
              speed: 1500, //滚动速度2500
              // mousewheel: true, //开启鼠标
              autoplay: {
                delay: 0,
                stopOnLastSlide: false,
                disableOnInteraction: false
              }
            });
          });
        }
      });
    },
    serviceMouseleave(swipers) {
      if (swipers) {
        swipers.autoplay.start();
      }
    },
    serviceMouseenter(swipers) {
      if (swipers) {
        swipers.autoplay.stop();
      }
    },
    newDetails(id) {
      // this.$router.push(`/information/detail?id=${id}`);
      let routeData = this.$router.resolve({
        path: `/information/detail?id=${id}`
      });
      window.open(routeData.href, "_blank");
    },
    caseDetails(id) {
      // this.$router.push(`/customer-case/detail?id=${id}`);
      let routeData = this.$router.resolve({
        path: `/customer-case/detail?id=${id}`
      });
      window.open(routeData.href, "_blank");
    }

    // getIntroduce() {
    //   this.$api.getIntroduce().then(res => {
    //     this.data = res.data;
    //     let map = this.aMap;

    //     AMap.service("AMap.PlaceSearch", function() {
    //       //回调函数
    //       //实例化PlaceSearch
    //       let placeSearch = new AMap.PlaceSearch({
    //         pageSize: 5, //每页显示多少行
    //         pageIndex: 1, //显示的下标从那个开始
    //         type: "商务住宅;产业园区;产业园区",
    //         city: "010", //城市
    //         map: map, //显示地图
    //         panel: "result" //服务显示的面板
    //       }); //TODO: 使用placeSearch对象调用关键字搜索的功能
    //       placeSearch.search(res.data.address, function(status, result) {
    //         if (status == "complete" && result.info == "OK") {
    //           console.log("address", status, result);
    //           let markerList = [];
    //           let pois = result.poiList.pois;
    //           map.setZoomAndCenter(14, [
    //             pois[0].location.lng,
    //             pois[0].location.lat
    //           ]);
    //           let marker = new AMap.Marker({
    //             position: new AMap.LngLat(
    //               pois[0].location.lng,
    //               pois[0].location.lat
    //             ),
    //             title: pois[0].name
    //           });
    //           markerList.push(marker);
    //           map.add(markerList);
    //         }
    //       });
    //     });
    //   });
    // }
  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
